import ShadowCard from '../components/ShadowCard'
import Tuner from '../components/Tuner'
import TunerHome from '../components/TunerHome'
import styles from '../components/Page.module.scss'
import useTranslation from '../translations/useTranslation'
import Preview from './chordchord/Preview/Preview'

const SHOW_SO_BANNER = process.env.NEXT_PUBLIC_SHOW_SO_BANNER === 'true'

function Page(props) {
  const { pageData } = props
  const { t } = useTranslation()

  return (
    <>
      <ShadowCard>{props.instrument ? <Tuner instrument={props.instrument} /> : <TunerHome />}</ShadowCard>

      {props.instrument && (
        <ShadowCard className={styles.previewCard}>
          <Preview />
        </ShadowCard>
      )}

      {/* <ChordChordBanner></ChordChordBanner> */}

      <h2 className={styles.heading}>{t('howToUseHeader')}</h2>
      <ShadowCard>
        <div className={styles.textContainer}>{t('howToUseText', { pageData })}</div>
      </ShadowCard>
    </>
  )
}

export default Page
