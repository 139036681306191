import classNames from 'classnames'
import styles from './ShadowCard.module.scss'

export default function ShadowCard({ className, children }) {
  return (
    <div className={classNames(styles.container, className)}>
      <div className={styles.card}>{children}</div>
    </div>
  )
}
