import { useEffect } from 'react'

const useClickOutsideAlerter = (ref, onClose) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref?.current) {
        if (!ref?.current?.contains(event.target)) {
          onClose(event)
        }
      } else {
        if (ref?.contains && !ref?.contains(event.target)) {
          onClose(event)
        }
      }
    }

    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [ref])
}

export default useClickOutsideAlerter
