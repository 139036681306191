import React, { useEffect, useRef, useState } from 'react'

import ArrowIcon from '../../../public/chordchord/arrow.svg'
import PlayIcon from '../../../public/chordchord/play.svg'
import PauseIcon from '../../../public/chordchord/pause.svg'
import ResetIcon from '../../../public/chordchord/reset.svg'
import styles from './Preview.module.scss'
import classNames from 'classnames'
import GuitarChord from '../GuitarChord/GuitarChord'
import useClickOutsideAlerter from '../../../hooks/clickOutsideAlerterHook'

const chordDurationSeconds = 2

const getChordId = (prog, ch) => {
  return `prog_${prog.id}-chord_${ch.id}`
}
const getChordSource = (prog, ch) => {
  return `/progressions/${prog.id}/${ch.id}.mp3`
}

const Preview = () => {
  const audioElementsRef = useRef([])
  const playChordTimeoutsRef = useRef([])
  const progDropdownRef = useRef(null)
  const keyDropdownRef = useRef(null)

  const [progressions, setProgressions] = useState([])

  const [prog, setProg] = useState(null)
  const [progDropdownOpened, setProgDropdownOpened] = useState(false)
  const [keyTooltipOpened, setKeyTooltipOpened] = useState(false)

  const [isPlaying, setIsPlaying] = useState(false)
  const [activeChordId, setActiveChordId] = useState(-1)

  useClickOutsideAlerter(progDropdownRef, () => setProgDropdownOpened(false))
  useClickOutsideAlerter(keyDropdownRef, () => setKeyTooltipOpened(false))

  useEffect(() => {
    ;(async () => {
      const progressionsConfigFile = await fetch('/progressions/index.json')
      const progressionsIds = await progressionsConfigFile.json()

      const allProgressions = await Promise.allSettled(
        progressionsIds.map(async (progressionsId) => {
          const progFile = await fetch(`/progressions/${progressionsId}/index.json`)
          const prog = await progFile.json()
          return prog
        }),
      ).then((res) => res.map((v) => v.value))

      if (!allProgressions?.length) return

      setProgressions(allProgressions)
      setProg(allProgressions[0])
    })()
  }, [])

  const handlePlayPause = () => {
    setIsPlaying((v) => {
      const newV = !v

      if (newV) {
        let chords = prog.parts[0].chords
        const activeChordIndex = chords.findIndex((ch) => ch.id === activeChordId)

        if (activeChordIndex !== -1) chords = [...chords.slice(activeChordIndex), ...chords.slice(0, activeChordIndex)]

        const chordDurationMiliseconds = chordDurationSeconds * 1000
        chords.forEach((chord, index) =>
          playChord(chord, index * chordDurationMiliseconds, chords.length * chordDurationMiliseconds),
        )
      } else {
        audioElementsRef.current.forEach((audioElem) => audioElem.pause())
        audioElementsRef.current = []

        playChordTimeoutsRef.current.forEach((timeout) => clearTimeout(timeout))
        playChordTimeoutsRef.current = []
      }

      return newV
    })
  }
  const handleReset = () => {
    if (isPlaying) handlePlayPause()
    setActiveChordId(-1)
  }
  const playChord = (ch, delay = 0, progDuration = 0) => {
    const chordAudioId = getChordId(prog, ch)
    const audioElem = document.getElementById(chordAudioId)

    audioElementsRef.current.push(audioElem)

    audioElem.load()
    audioElem.addEventListener('ended', () => {
      setActiveChordId((v) => {
        if (v !== ch.id) return v
        if (progDuration) setIsPlaying(false)
        return -1
      })
    })

    const playChordTimeoutFn = () => {
      audioElem.play()
      setActiveChordId(ch.id)

      if (progDuration) {
        setIsPlaying(true)

        const loopTimeout = setTimeout(playChordTimeoutFn, progDuration)
        playChordTimeoutsRef.current.push(loopTimeout)
      }
    }

    const playChordTimeout = setTimeout(playChordTimeoutFn, delay)
    playChordTimeoutsRef.current.push(playChordTimeout)
  }

  const renderEditButton = () => {
    return (
      <div
        className={styles.editBtn}
        onClick={() => {
          const newProg = JSON.parse(JSON.stringify(prog))

          newProg.parts.forEach((part, partIndex) => {
            part.chords.forEach((chordRaw, chordIndex) => {
              const { notes, strings, ...chord } = chordRaw

              newProg.parts[partIndex].chords[chordIndex] = chord
            })
          })

          let urlParams = '?utm_source=guitartuner&prog='

          // window.open(`http://localhost:3000/${urlParams}${JSON.stringify(prog)}`)
          // window.open(`https://dev-aurora.chordchord.app/${urlParams}${JSON.stringify(prog)}`)
          window.open(`https://chordchord.com/${urlParams}${JSON.stringify(prog)}`)
        }}
      >
        Edit Progression
      </div>
    )
  }
  const renderKeyTooltip = () => {
    return (
      <div className={styles.keyTooltip} ref={keyDropdownRef}>
        <div className={styles.keyTooltipTitle}>Change The Key</div>
        <div className={styles.keyTooltipText}>Customize chords, generate melodies, add drums & more!</div>
        {renderEditButton()}
      </div>
    )
  }
  const renderProgDropdown = () => {
    return (
      <div className={styles.progDropdown} ref={progDropdownRef}>
        {progressions.map((progression) => (
          <div
            className={classNames(styles.progDropdownCell, {
              [styles.progDropdownCellActive]: JSON.stringify(prog) === JSON.stringify(progression),
            })}
            onClick={() => {
              setProg(progression)
              setProgDropdownOpened(false)
              if (isPlaying) handlePlayPause()
            }}
          >
            {progression.name}
          </div>
        ))}
      </div>
    )
  }

  if (!prog) return null
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.title}>Tuned Up? Play Some Chords</div>

        <div className={styles.progSelectContainer}>
          <div className={styles.progSelectText}>Select Genre</div>

          <div
            className={classNames(styles.progSelectContent, { [styles.progSelectContentActive]: progDropdownOpened })}
            onClick={() => setProgDropdownOpened((v) => !v)}
          >
            <span>{prog.name}</span>
            <ArrowIcon />
            {renderProgDropdown()}
          </div>
        </div>
      </div>

      <div className={styles.content}>
        <div className={styles.chords}>
          {prog.parts[0].chords.map((chord) => (
            <div
              key={chord.id}
              className={classNames(styles.chord, { [styles.chordActive]: chord.id === activeChordId })}
              onClick={(e) => {
                e.stopPropagation()
                playChord && playChord(chord)
              }}
            >
              <GuitarChord chord={chord} active={chord.id === activeChordId} />

              <div className={styles.chordFooter}>
                <div className={styles.chordName}>{chord.name}</div>
                <div className={styles.chordDegree}>{chord.degree}</div>
              </div>

              <audio id={getChordId(prog, chord)} className={styles.chordAudio} preload={true}>
                <source src={getChordSource(prog, chord)} />
              </audio>
            </div>
          ))}
        </div>

        <div className={styles.controls}>
          <div className={styles.resetBtn} onClick={handleReset}>
            <ResetIcon />
          </div>

          <div className={classNames(styles.playBtn, { [styles.playBtnActive]: isPlaying })} onClick={handlePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </div>

          <div
            className={classNames(styles.keyContainer, { [styles.keyContainerActive]: keyTooltipOpened })}
            onClick={() => setKeyTooltipOpened((v) => !v)}
          >
            <div className={styles.key}>C</div>
            <div className={styles.scale}>Minor</div>

            {renderKeyTooltip()}
          </div>
        </div>
      </div>

      <div className={styles.footer}>{renderEditButton()}</div>
    </div>
  )
}

export default Preview
