import React from 'react'

import styles from './GuitarChord.module.scss'
import classNames from 'classnames'

const GuitarChord = ({ chord, active }) => {
  const { id, notes, strings } = chord

  const tabs = Object.values(strings || {})
    .filter((str) => typeof str === 'number')
    .map((str) => Number(str))
  const firstTab = Math.min(...tabs)
  const lastTab = Math.max(...tabs)
  const tabsDiapason = Math.max(4, lastTab - firstTab)
  const tooltipId = `${id}_chord_guitar`

  const renderNote = (ind) => {
    const value = strings[ind]
    const note = notes[ind]
    if (value === 'X') {
      return (
        <div key={ind} className={styles.unusedNote}>
          X
        </div>
      )
    }
    if (value === 'FREE') {
      return (
        <div key={ind} className={styles.emptyNote}>
          {note}
        </div>
      )
    }
    return (
      <div
        data-first-tab={value === 1}
        className={styles.note}
        key={ind}
        style={{ marginLeft: `${((value - firstTab + 0.5) / tabsDiapason) * 100}%` }}
      >
        {note}
      </div>
    )
  }

  return (
    <>
      <div
        data-tooltip-id={tooltipId}
        className={classNames(styles.container, { [styles.active]: active })}
        // onClick={() => playerConfig.player.chordPlayer.playGuitarChord(chord)}
      >
        <div className={styles.background}>
          <div className={styles.leftBorder} />

          <div className={styles.tabsContainer}>
            {new Array(tabsDiapason)
              .fill(0)
              .map((acc, ind) =>
                ind === 0 ? null : (
                  <div key={ind} style={{ left: `${(ind / tabsDiapason) * 100}%` }} className={styles.tabDivider} />
                ),
              )}
          </div>

          <div className={styles.strings}>
            {new Array(6).fill(0).map((_, ind) => (
              <div key={ind} className={styles.string} data-disabled={strings[ind] === 'X'} />
            ))}
          </div>

          <div className={styles.notes}>{new Array(6).fill(0).map((_, ind) => renderNote(ind))}</div>

          <div
            className={styles.tabLabel}
            data-first-tab={firstTab === 1}
            style={{ left: `${(1 / tabsDiapason) * 50}%` }}
          >
            {firstTab}
          </div>
        </div>
      </div>
    </>
  )
}

export default GuitarChord
